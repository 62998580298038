/* eslint react-hooks/exhaustive-deps: "warn" */

import { useCallback } from 'react';

import cn from 'classnames';

import { getCookie } from 'libs/node';

import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';

import type { AgentData, TemplatesType } from 'features/agents/helpers/AgentsContext/AgentsContext.types';
import { useAgentsContext } from 'features/agents/helpers/AgentsContext/AgentsContext';
import { agentTypeKeyMap, authConfig, isFreeType, isPaidType, sortByType } from 'features/agents/helpers/utils';

import styles from './CardsAgents.module.scss';

const baseClass = 'cards-agents';

export const iconTypes = {
  check_company_status: 'status-check',
  check_company_registration_number: 'registration',
  check_vat_number: 'vat-number',
  find_company_lei: 'lei',
  retrieve_company_sic_code: 'sic-code',
  competitor_analysis: 'competitor',
  lead_prospector: 'prospector',
  business_credit_score_check: 'score',
  credit_limit_advisor: 'credit-limit',
  check_company_financials: 'financials',
  check_company_shareholders: 'shareholders',
  identify_beneficial_owners: 'owner',
  business_activity_monitor: 'monitoring',
  director_check: 'director',
  supply_chain_risk_score: 'risk',
  payment_behavior_analysis: 'analysis',
  email_finder: 'email',
  phone_number_finder: 'phone-nr',
  check_court_judgment_status: 'report',
  total_addressable_market_estimator: 'market-estimator',
  funding_history_tracker: 'funding-history',
  esg_score: 'esg-score',
  customer_sentiment_analysis_agent: 'customer-analysis',
  peps_and_sanction_agent: 'sanction',
  intellectual_property_agent: 'property',
  company_reputation_score_agent: 'reputation-score',
  supplier_verification_agent: 'verification',
  regulatory_compliance_check_agent: 'regulatory-compliance',
  cybersecurity_risk_assessment_agent: 'cybersecurity',
  ipo_monitor_agent: 'ipo-monitor',
  website_traffic_agent: 'website',
} as const;

export type AgentName = keyof typeof iconTypes;

type Props = {
  className?: string;
};

type CardProps = {
  active?: boolean;
  card: AgentData;
  onClick: (type: TemplatesType, id?: string) => void;
  type: TemplatesType;
};

const Card = ({ active, card, onClick, type }: CardProps) => {
  const cardIcon = card.name in iconTypes && `company-${iconTypes[card.name as AgentName]}`;

  const onClickHandler = useCallback(() => {
    onClick(isPaidType(card.type) ? 'book-demo' : getCookie(authConfig.ACCESS_KEY) ? 'agent' : 'register', card.slug);
  }, [card.slug, card.type, onClick]);

  if (!cardIcon || active) {
    return null;
  }

  return (
    <li className={styles[`${baseClass}-card`]} onClick={onClickHandler}>
      <div className={styles[`${baseClass}-icon`]}>
        <Icon type={cardIcon} />
      </div>

      <div className={styles[`${baseClass}-inner`]}>
        <div className={styles[`${baseClass}-inner-text`]}>
          <p className={styles[`${baseClass}-title`]}>{card.title}</p>

          <div className={styles[`${baseClass}-description`]} dangerouslySetInnerHTML={{ __html: card.description }} />
        </div>

        <div className={styles[`${baseClass}-link`]}>
          <Button loadingDelay={0} size={32} type="invert">
            Get
            <Icon type="company-arrow-right" className={styles[`${baseClass}-arrow`]} />
          </Button>
        </div>
      </div>

      {type !== 'agents' && (
        <div className={cn(styles[`${baseClass}-type`], styles[`${baseClass}-type-${card.type.toLowerCase()}`])}>
          {card.type}
        </div>
      )}
    </li>
  );
};

export const CardsAgents = ({ className }: Props) => {
  const { data, updateType, type, agent } = useAgentsContext();

  const renderList = (cards: any[], title?: string) => {
    return (
      <>
        {title && <h2 className={styles[`${baseClass}-header-title`]}>{title}</h2>}

        <ul className={cn(styles[`${baseClass}-cards`], className)}>
          {cards.map((card) => {
            if (isFreeType(card.type) && card.name in agentTypeKeyMap === false) {
              return null;
            }

            return (
              <Card
                key={card.id}
                {...(type !== 'agents' && { active: card.id === agent })}
                card={card}
                onClick={updateType}
                type={type}
              />
            );
          })}
        </ul>
      </>
    );
  };

  if (type === 'agents') {
    const [freeCards, paidCards] = data.reduce<[AgentData[], AgentData[]]>(
      (acc, curr) => {
        if (isFreeType(curr.type)) {
          acc[0].push(curr);
        } else if (isPaidType(curr.type)) {
          acc[1].push(curr);
        }

        return acc;
      },
      [[], []],
    );

    return (
      <>
        {renderList(freeCards, 'Free')}
        {renderList(paidCards, 'Paid')}
      </>
    );
  }

  return renderList([...data].sort(sortByType));
};
