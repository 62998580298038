export const formatDate = (d: string) => {
  const [yyyy, mm, dd] = d.split(/[/:\-T]/);

  return `${dd}/${mm}/${yyyy}`;
};

export const formatDateTime = (d: string) => {
  const [yyyy, mm, dd, hh, mi] = d.split(/[/:\-T]/);

  return `${dd}/${mm}/${yyyy} ${hh}:${mi}`;
};

export const getNextHour = () => {
  const timeNow = new Date().getTime();
  return new Date(timeNow + 60 * 60 * 1000);
};
