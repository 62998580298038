export const blog = ['due_diligence', 'top_gdb_companies', 'sales', 'news', 'marketing', 'gdb_vs_competitors'];

export const caseStudiesCategories = ['category_1', 'category_2', 'category_3'];

export const customersTypesWithUnderscore = {
  category_1: 'sales-and-marketing',
  category_2: 'compliance',
  category_3: 'data-enrichment',
} as { [key: string]: string };

export const exceptionSitemapPages = ['customers', 'blog', 'author'];

export const careerTypes = ['engineering', 'sales', 'customer_success', 'marketing', 'operational'];
