/* eslint react-hooks/exhaustive-deps: "warn" */

import { useCallback, useMemo } from 'react';

import cn from 'classnames';

import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';
import { DropFileInput } from 'ui/atoms/DropFileInput/DropFileInput';
import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './UploadDropzone.module.scss';

interface Props {
  className?: string;
  accept?: string[];
  error?: boolean;
  loading?: boolean;
  hasAccess?: boolean;
  onDrop?: (value: File[]) => void;
  size?: 'small' | undefined;
  title?: string;
}

export const UploadDropzone = ({
  onDrop,
  title,
  className = '',
  accept = ['.csv', '.txt', '.xlsx'],
  error = false,
  loading = false,
  hasAccess,
  size,
}: Props) => {
  const onDropHandler = useCallback(
    (file: File[]) => {
      if (hasAccess !== false && typeof onDrop === 'function') {
        onDrop(file);
      }
    },
    [hasAccess, onDrop],
  );

  const showAccessError = useMemo(() => loading === false && hasAccess === false, [loading, hasAccess]);

  return (
    <DropFileInput
      formats={accept}
      onUpload={onDropHandler}
      className={cn(styles['upload-dropzone'], size && styles.small, className)}
    >
      {size && (
        <div className={styles['upload-dropzone-image']}>
          {loading ? <LoaderSpin/> : <Icon type="upload-files" size="big"/>}
        </div>
      )}

      <span className={styles['upload-title']}>
        {showAccessError
          ? 'You have reached the import file limits'
          : error
            ? Array.isArray(error)
              ? error.join(', ')
              : error
            : 'Drag and drop file here'}
      </span>

      <span>
        {title || `Load the ${accept.reduce(
          (acc, current, key) => (acc += `${key !== 0 ? (key + 1 !== accept.length ? ', ' : ' and ') : ''}${current}`),
          '',
        )} file`}
      </span>

      <Button size={48} className={styles['upload-button']} loading={loading}>
        Upload File
      </Button>
    </DropFileInput>
  );
};
