/* eslint react-hooks/exhaustive-deps: "warn" */

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import cn from 'classnames';

import { useRouter } from 'libs/router/useRouter';
import { clearTimeoutIfExists } from 'libs/node';

import { ContentContext } from 'context/ContentContext';

import { DownloadSample } from 'ui/molecules/DownloadSample/DownloadSample';
import { BlogSidebar } from 'ui/organisms/BlogSidebar/BlogSidebar';
import { Breadcrumbs } from 'ui/atoms/Breadcrumbs/Breadcrumbs';
import { Faq } from 'ui/atoms/Faq/Faq';
import { DynamicImport } from 'ui/DynamicImport';

import { BlogNote } from 'features/articles/atoms/BlogNote/BlogNote';
import { Benefits } from 'features/articles/organisms/Benefits/Benefits';
import { Collapse } from 'features/articles/organisms/Collapse/Collapse';
import { ReadingBar } from 'features/articles/organisms/ReadingBar/ReadingBar';
import { BookDemoLoader } from 'features/articles/organisms/BookDemo/BookDemoLoader';
import { FindMoreLoader } from 'features/articles/organisms/FindMore/FindMoreLoader';

import styles from './ArticlePage.module.scss';

import {
  addLazyLoading,
  downloadSamplePattern,
  findOutPattern,
  formPattern,
  getLastTitleKey,
  getTitles,
  parsePropsOfTag,
  tagsPattern,
} from './utils';

const ArticlePage = () => {
  const { query, asPath } = useRouter();
  const { data: content, secondaryNavigation, pageVars, links, additionals } = useContext(ContentContext);

  const contentRef = useRef<HTMLDivElement>(null);
  const scrollArticlesRef = useRef<HTMLDivElement>(null);
  const contentParts = useRef<{ title: string; id: number }[]>([]);
  const collapseDebounce = useRef<NodeJS.Timeout>();

  type CollapsedKey = (typeof titles)[number]['key'] | null;
  const [collapsed, setCollapsed] = useState<CollapsedKey>(getLastTitleKey(content.has_article));
  const [collapsedLoading, setCollapsedLoading] = useState<CollapsedKey>(null);

  const lastTag = useRef<string | null>(null);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);
  const article = useMemo(() => content.has_article, [content.has_article]);
  const titles = useMemo(() => getTitles(article), [article]);

  useEffect(() => {
    return () => {
      clearTimeoutIfExists(collapseDebounce.current);
    };
  }, []);

  const onCollapse = useCallback((key: (typeof titles)[number]['key'], value: boolean) => {
    clearTimeoutIfExists(collapseDebounce.current);

    lastTag.current = '';
    setCollapsed(null);

    if (value === false) {
      return;
    }

    setCollapsedLoading(key);

    window.requestAnimationFrame(() => {
      if (contentRef.current !== null) {
        const rect = contentRef.current.getBoundingClientRect();
        window.scrollTo(0, rect.top + window.scrollY - 125);
      }

      collapseDebounce.current = setTimeout(() => {
        lastTag.current = '';
        setCollapsed(key);
        setCollapsedLoading(null);
      }, 200);
    });
  }, []);

  const renderFormTag = useCallback(
    (tag: string, key = 0) => {
      const { button, text } = parsePropsOfTag(tag) || {};

      return (
        <DynamicImport fallback={() => <BookDemoLoader />}>
          {() => {
            const Component = dynamic(() => import('features/articles/organisms/BookDemo/BookDemo'), {
              ssr: false,
              loading: () => <BookDemoLoader />,
            });

            return (
              <Component
                key={key}
                type="book"
                text={text}
                button={button}
                className={styles[`${titles.length > 1 ? 'articles-collapse-book-demo' : 'book-demo'}`]}
              />
            );
          }}
        </DynamicImport>
      );
    },
    [titles],
  );

  const renderFindOutTag = useCallback(
    (tag: string, key = 0) => {
      const { button, title, text } = parsePropsOfTag(tag) || {};

      return (
        <DynamicImport fallback={() => <FindMoreLoader />}>
          {() => {
            const Component = dynamic(() => import('features/articles/organisms/FindMore/FindMore'), {
              ssr: false,
              loading: () => <FindMoreLoader />,
            });

            return (
              <Component
                key={key}
                title={title}
                text={text}
                button={button}
                className={styles[`${titles.length > 1 ? 'articles-collapse-find-more' : 'find-more'}`]}
              />
            );
          }}
        </DynamicImport>
      );
    },
    [titles],
  );

  const renderDownloadSampleTag = useCallback(
    (tag: string, key = 0) => {
      const { button, text } = parsePropsOfTag(tag) || {};

      return (
        <DownloadSample
          key={key}
          title={text}
          button={button || article?.sample_title || commonPageVars.request_sample_button}
        />
      );
    },
    [article?.sample_title, commonPageVars.request_sample_button],
  );

  const parseContentsTable = useCallback((newContent: string): string => {
    /** Reset content parts from previous execution */
    contentParts.current = [];

    let countParts = 0;

    return newContent
      .replace(/&quot;/g, '"')
      .replace(
        /<h2[^>]*>([\s\S]*?)<\/h2>/g,
        (_: string, innerText: string): string => {
          let cleanTitle: string = innerText.replace(/<[^>]+>/g, '');

          cleanTitle = cleanTitle.replace(/&#(\d+);/g, (_, dec: string) => String.fromCharCode(Number(dec)));

          const htmlEntitiesMap: Record<string, string> = {
            '&nbsp;': ' ',
            '&amp;': '&',
            '&ndash;': '–',
            '&hellip;': '…',
          };

          cleanTitle = cleanTitle.replace(/&nbsp;|&amp;|&ndash;|&hellip;/g, (entity) => htmlEntitiesMap[entity] || ' ').trim();

          if (!cleanTitle) return '';

          countParts += 1;
          contentParts.current.push({
            title: cleanTitle.replace(`${countParts}.`, '').trim(),
            id: countParts,
          });

          return `<h2 id="${countParts}" class="article-scroll">${cleanTitle}</h2>`;
        }
      );
  }, []);

  const renderContent = useCallback(
    (content?: string) => {
      if (typeof content !== 'string') {
        return null;
      }

      /** Save all h2's, attach anchors to each of them */
      /** Split content into small pieces so we can render call to action elements between them */
      /** Render either text or tag's pre-defined component */
      return addLazyLoading(parseContentsTable(content))
        .split(tagsPattern)
        .map((item, key, arr) => {
          if (titles.length > 1 && key === arr.length - 2 && item.match(tagsPattern)) {
            lastTag.current = item;

            return null;
          }

          if (item.match(downloadSamplePattern)) {
            return renderDownloadSampleTag(item, key);
          }

          if (item.match(formPattern)) {
            return renderFormTag(item, key);
          }

          if (item.match(findOutPattern)) {
            return renderFindOutTag(item, key);
          }

          return (
            <div key={key} className={styles['articles-post-wrapper']}>
              <div className={styles['articles-post-text']} dangerouslySetInnerHTML={{ __html: item }} />
            </div>
          );
        });
    },
    [parseContentsTable, titles.length, renderDownloadSampleTag, renderFormTag, renderFindOutTag],
  );

  const url = useMemo(
    () =>
      `${process.env.HOST || ''}${links.reduce((acc, current) => {
        if (current.key === query.lang) {
          acc = current.link;
        }

        return acc;
      }, '')}`,
    [links, query],
  );

  const breadcrumbs = useMemo(() => {
    const isCaseStudy = Boolean(article?.case_studies_type);
    const slug = secondaryNavigation[isCaseStudy ? 'customers' : 'blog']?.slug;

    return [
      { slug: slug, query: { paage: 1 }, name: secondaryNavigation[isCaseStudy ? 'customers' : 'blog']?.title, key: 1 },
      {
        /** redirect to customers page if article has case study type */
        slug: slug,
        query: {
          paage: 1,
          type: isCaseStudy ? article?.case_studies_type : article?.type !== 'all_topics' ? article?.type : '',
        },
        name: isCaseStudy
          ? commonPageVars[`case_studies_${article?.case_studies_type}`]
          : commonPageVars[`blog_${article?.type}`],
        key: 2,
      },
      { name: article?.title, isLast: true, key: 3 },
    ];
  }, [article?.case_studies_type, article?.type, article?.title, secondaryNavigation, commonPageVars]);

  if (!article) {
    return <div className={styles['w-layout-base-1 m-auto']}>Oops! {"There's"} no article on Global CMS.</div>;
  }

  return (
    <>
      <ReadingBar
        scrollArticlesRef={scrollArticlesRef}
        className={titles.length > 1 && collapsed === null ? 'w-0' : ''}
      />

      <Head>
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
      </Head>

      <Script
        id="blog-posting-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BlogPosting',
            headline: content.title,
            image: `${process.env.HOST || ''}/${content.background?.url}`,
            datePublished: content.created_at,
            mainEntityOfPage: `${process.env.HOST || ''}${asPath}`,
            description: content.meta_description,
            author: [
              {
                '@type': 'Person',
                name: article.has_author?.full_name,
                url: `${process.env.HOST || ''}/${secondaryNavigation.author.slug}?id=${article.has_author?.id}`,
              },
            ],
            publisher: {
              '@type': 'Organization',
              name: 'Global Database',
              url: 'https://www.globaldatabase.com/',
            },
          }),
        }}
      />

      <div className={styles['articles-simple']}>
        <div className={styles['articles-simple__layout']}>
          <Breadcrumbs data={breadcrumbs} />
        </div>

        <div ref={scrollArticlesRef}>
          <BlogNote />

          <div className={styles['articles-simple__content']} ref={contentRef}>
            {titles.length > 1 ? (
              <>
                <div className={styles['articles-collapse-block']}>
                  {titles.map((item) => {
                    const opened = collapsed === item.key;

                    return (
                      <Collapse
                        key={item.key}
                        title={item.data}
                        opened={opened}
                        onChange={(value) => onCollapse(item.key, value)}
                        iconType="arrow-right"
                        iconClassName={`${styles['title-arrow']} ${opened ? styles['collapsed'] : ''}`}
                        className={styles['articles-post-wrapper']}
                        loading={collapsedLoading === item.key}
                      >
                        {opened ? renderContent(article[`content${item.key}`]) : null}
                      </Collapse>
                    );
                  })}
                </div>

                {lastTag.current && (
                  <>
                    {lastTag.current.match(downloadSamplePattern) && renderDownloadSampleTag(lastTag.current)}
                    {lastTag.current.match(formPattern) && renderFormTag(lastTag.current)}
                    {lastTag.current.match(findOutPattern) && renderFindOutTag(lastTag.current)}
                  </>
                )}
              </>
            ) : (
              <div className={styles['articles-simple__article']}>
                <div>{renderContent(article[`content`])}</div>

                {article && (
                  <BlogSidebar
                    author={article.has_author}
                    route={secondaryNavigation.author.slug}
                    articleParts={contentParts.current}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <Benefits
          hasSliderFeature
          hasEmptyPlaceholder={false}
          withViewMore
          title={commonPageVars.benefits_title}
          className={cn(styles['articles-benefits-wrapper'], additionals.faq && styles['mb-0'])}
          limit={9}
        />

        {additionals.faq && additionals.faq.data.length > 0 && (
          <Faq title={commonPageVars.faq_2} data={additionals.faq.data} />
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default ArticlePage;
