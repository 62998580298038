import cn from "classnames";

import styles from './Score.module.scss'

const baseClass = 'score';

type Props = {
  value: string,
}

export const Score = ({ value, ...restProps }: Props) => {
  if (Boolean(value) === false) {
    return <span className={styles[`${baseClass}-inactive`]}>---</span>;
  }

  return (
    <span
      className={cn(styles[`${baseClass}-wrapper`], styles[`${baseClass}-${(value || 'n-a').toLowerCase()}`])}
      {...restProps}
    >
    {value || 'N/A'}
  </span>
  );
};
