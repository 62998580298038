export const isFreeType = (type: string) => ['Free', 'free'].includes(type);

export const isPaidType = (type: string) => ['Paid', 'paid'].includes(type);

export const authConfig = {
  ACCESS_KEY: 'jwt-access',
  REFRESH_KEY: 'jwt-refresh',
  CURRENT_SOCIAL_KEY: 'current-social',
  CURRENT_AGENT: 'current-agent',
  MAIN_TEMPLATE: 'agents',
  AGENTS_APP: 'ai_agent',
};

export const agentTypeKeyMap: Record<string, string> = {
  check_company_status: 'status',
  check_company_registration_number: 'reg_number',
  check_vat_number: 'vat_number',
  retrieve_company_sic_code: 'sic_code',
  // find_company_lei: undefined, // * Currently not available
};

type A = { [key: string]: unknown; type: string };
type B = A;
export const sortByType = (a: A, b: B) => {
  if (isFreeType(a.type) && !isFreeType(b.type)) {
    return -1;
  }

  if (!isFreeType(a.type) && isFreeType(b.type)) {
    return 1;
  }

  return 0;
};
